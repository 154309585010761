import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import BannerHome from 'components/Home/BannerHome'
import Filter from 'components/Home/Filter'
import Preview from 'components/Home/Preview'
import OverSnel from 'components/Home/OverSnel'

// Third Party
import styled from 'styled-components'
import Services from '../components/Home/Services'
import Magazine from '../components/Home/Magazine'
import Nieuws from '../components/Home/Nieuws'
import Locaties from '../components/Home/Locaties'
import CTA from '../components/Home/CTA'

const Home = styled.div``

const HomeTemplate = ({
  data: {
    home,
    woningen: { edges: woningen },
  },
}) => (
  <Layout>

      <>
        {home.seo && (
          <SEO
            seo={home.seo}
            socialImage={
              home.pageHome.bannerhome.image.localFile.childImageSharp.meta.src
            }
          />
        )}
        <Home className="position-relative">
          <BannerHome
            fields={home.pageHome.bannerhome}
            autoScroll={false}
            data={home}
          />
          <Filter />
          <Services fields={home.pageHome.serviceshome} />
          <Magazine fields={home.pageHome.magazinehome} />
          <Preview
            loadMoreButton
            title={home.pageHome.aanbodhome.title}
            data={woningen}
          />
          <OverSnel data={home.pageHome.aboutushome} />
          <Nieuws fields={home.pageHome.newshome} />
          <CTA fields={home.pageHome.cta} />
          <Locaties
            fields={home.pageHome.locationshome.component.componentLocaties}
          />
        </Home>
      </>

  </Layout>
)

export default HomeTemplate

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    home: wpPage(databaseId: { eq: $databaseId }) {
      title
      id
      databaseId
      uri
      pageHome {
        fieldGroupName
        bannerhome {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 1020)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
          secondTitle
          description
          link {
            target
            title
            url
          }
        }
        serviceshome {
          title
          service {
            ... on WpPage {
              title
              databaseId
              uri
              recapService {
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
        magazinehome {
          title
          description
          link {
            target
            title
            url
          }
        }
        aanbodhome {
          title
        }
        aboutushome {
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 800)
              }
            }
          }
          link {
            target
            title
            url
          }
          usps {
            icon {
              localFile {
                publicURL
              }
            }
            text
          }
          beoordeling {
            description
            number
          }
        }
        newshome {
          title
          items {
            ... on WpPost {
              title
              uri
              databaseId
              recapPost {
                title
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, width: 800)
                    }
                  }
                }
              }
            }
          }
        }
        cta {
          image {
            localFile {
              publicURL
            }
          }
          title
          link {
            title
            url
          }
        }
        locationshome {
          component {
            ... on WpComponent {
              id
              componentLocaties {
                title
                locaties {
                  locationName
                  address
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100, width: 800)
                      }
                    }
                  }
                  link {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
    woningen: allWpWoning(limit: 6) {
      edges {
        node {
          title
          slug
          databaseId
          recapWoning {
            image
            city
            address
            typeHouse
            typeOfHouse
            bathrooms
            country
            description
            houseId
            image
            koop
            koopconditie
            huur
            huurconditie
            location
            buildYear
            numberRooms
            numberSleeprooms
            price
            province
            status
            totaleWoonoppervlakte
            totaleOppervlakte
            type
            zipcode
          }
        }
      }
    }
  }
`
