/* eslint-disable import/no-duplicates */
import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import ButtonAlt from '../Elements/ButtonAlt'



const StyledBannerHome = styled.section`
  padding: 42px 0;

  & h1 {
    font-weight: bold;
    margin-bottom: 0;
    
    @media (min-width: 992px){
      font-size: 60px;
      line-height: normal;
    }
  }

  & h2 {
    font-weight: bold;

    @media (min-width: 992px){
      font-size: 24px;
      margin-bottom: 35px;
    }
  }
  
  @media (max-width: 767px){
    .image-col {
      order: -1;
      margin-bottom: 2rem;
    }
  }
`

const StyledImg = styled(Plaatjie)`
  height: 560px;
  width: 100%;
  border-radius: 20px;

  @media (max-width: 991px) {
    height: 400px;
  }

  @media (max-width: 450px) {
    height: 250px;
  }
`



const BannerHome = ({ fields, className }) => (
  <StyledBannerHome>
    <div className="container">
      <div className={`row align-items-center justify-content-between ${className ? `${className}` : ``}`}>
        <div className="col-md-6 col-lg-4">
          <h1>{fields.title}</h1>
          <h2>{fields.secondTitle}</h2>
          {parse(fields.description)}
          <ButtonAlt to={fields.link.url}>{fields.link.title}</ButtonAlt>
        </div>
        <div className="col-md-6 col-lg-7 image-col">
          <StyledImg loading="eager" image={fields.image} alt="" />
        </div>
      </div>
    </div>
  </StyledBannerHome>
)

export default BannerHome
