import React from 'react'
import { graphql } from 'gatsby';

import Home from '../templates/home';

const HomePage = ({ data }) => (<Home data={data} />)

export default HomePage;

export const pageQuery = graphql`
  query {
    home: wpPage(databaseId: {eq: 7}) {
      title
      databaseId
      uri
      pageHome {
        bannerhome {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 1020)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
          secondTitle
          description
          link {
            target
            title
            url
          }
        }
        serviceshome {
          title
          service {
            ... on WpPage {
              title
              databaseId
              uri
              recapService {
              icon {
                localFile {
                  publicURL
                }
              }
            }
            }
          }
        }
        magazinehome {
          title
          description
          link {
            target
            title
            url
         }
        }
        aanbodhome {
          title
        }
        aboutushome {
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 800)
              }
            }
          }
          link {
            target
            title
            url
          }
          usps {
            icon {
              localFile {
                publicURL
              }
            }
            text
          }
          beoordeling {
            description
            number
          }
        }
        cta {
          image {
            localFile {
              publicURL
            }
          }
          title
          link {
            title
            url
          }
        }
        newshome {
          title
          items {
           ... on WpPost {
              title
              uri
              databaseId
              recapPost {
                title
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, width: 800)
                    }
                  }
                }
              }
            }
          }
        }
        locationshome {
          component {
            ... on WpComponent {
              id
              title
              componentLocaties {
                title
                locaties {
                  locationName
                  address
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100, width: 800)
                      }
                    }
                  }
                  link {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
    woningen: allWpWoning(limit: 6) {
      edges {
        node {
          title
          slug
          databaseId
          recapWoning {
            image
            city
            address
            typeHouse
            typeOfHouse
            bathrooms
            country
            description
            houseId
            image
            koop
            koopconditie
            huur
            huurconditie
            location
            buildYear
            numberRooms
            numberSleeprooms
            price
            province
            status
            totaleWoonoppervlakte
            totaleOppervlakte
            type
            zipcode
          }
        }
      }
    }
  }
`