import React from 'react'
import styled from 'styled-components'
import Content from 'components/Content'
import ButtonAlt from '../Elements/ButtonAlt'

const StyledMagazine = styled.div`
  height: 171px;
  border-radius: 20px;

  @media (max-width: 767px){
    height: auto;
    padding: 2rem 0;
  }

  & h4 {
    font-size: 40px;
    font-weight: bold;
    color: #fff;

    @media (max-width: 991px){
      font-size: 25px;
    }
  }

  & p {
    color: #fff;
    font-size: 20px;
    margin-bottom: 0;
  }
`

const Button = styled(ButtonAlt)`
background-color: ${(props) => props.theme.color.light} !important;
& a{
  color: ${(props) => props.theme.color.text.main} !important;
}
`

const Icon = styled.div`
  position: absolute;
  left: 4rem;
`

const Magazine = ({ fields }) => (
  <section className="py-4">
    <div className="container">
      <StyledMagazine className="d-flex flex-wrap color-background-contrast align-items-center position-relative">
        <Icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="223" height="228" viewBox="0 0 223 228" fill="none">
            <path opacity="0.1" d="M0 228V97.0097L110.959 0L183.444 65.2309L164.983 81.6121L110.725 32.7769L89.4838 51.3451L144.195 100.078L125.719 116.459L70.8761 67.6088L47.2654 88.2614L103.045 136.597L84.5393 153.008L28.6284 104.554L24.4738 108.18V203.443H90.0982V162.402L111.207 143.672L114.528 146.549L114.572 148.105V203.443H156.015V143.452L133.78 123.636L152.241 107.255L180.489 132.414V203.443H198.526V111.806L172.999 88.8338L191.461 72.4527L223 100.841V228H0Z" fill="white" />
          </svg>
        </Icon>
        <div className="col-md-2" />
        <div className="col-md-6">
          <h4>{fields.title}</h4>
          <Content content={fields.description} className="mb-4 mb-lg-0" />
        </div>
        <div className="col-md-4 text-md-center">
          <Button to={fields.link.url}>{fields.link.title}</Button>
        </div>
      </StyledMagazine>
    </div>
  </section>
)

export default Magazine