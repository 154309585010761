/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

// Components
import { Paragraph } from 'components/StyledComponents'
// import CustomLink from 'components/CustomLink'

// Images
import Groep from 'img/groep.png'

// Third Party
import styled from 'styled-components'

const FilterWrapper = styled.div`
  border-radius: 20px;
  
  @media (min-width: 992px){
    height: 300px;
  }

  @media (max-width: 991px){
    padding: 2rem 0;
  }
`

const RadioButtonContainer = styled.div`
 margin-bottom: 30px;
 position: relative;
 z-index: 10;
`

const RadioButton = styled.div`
  outline: none;
  transition: 0.25s;
  border-radius: 59px;
  background-color: ${(props) => props.theme.color.main};
  

  ${props => props.selected && `
    background-color: ${(props) => props.theme.color.secondary};
    ${Paragraph} {
      color: ${(props) => props.theme.color.text.main};
    }
  `}
`

const Input = styled.input`
  outline: none;
  border: none;
  box-shadow: none;
  height: 50px;
  width: ${props => props.theme.width};
  border-radius: 20px;

  ::placeholder {
    color: #c0c0c0;
  }
`

const InputContainer = styled.div`
position: relative;
z-index: 10;
`

const InputWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
  }
`

const StyledCustomLink = styled.div`
  ${Paragraph} {
    height: 47px;
    width: 141px;
    transition: 0.25s;
    border-radius: 59px;
    :hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 991px) {
    width: 100%;

    ${Paragraph} {
      width: 100%;
    }
  }
`

const PriceContainer = styled.div`
  ${Input} {
    position: relative;
    
    ::before {
      content: "€";
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  @media (max-width: 991px) {
    width: 100%;

    ${Input} {
      width: 100%;
      border-radius: 0;
    }
  }
`

const RangeContainer = styled.div`
  @media (max-width: 991px) {
    width: 100%;

    ${Input} {
      width: 100%;
      border-radius: 0;
    }
  }
`

const InnerContainer = styled.div`
  padding: 0 50px 0 50px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;


  @media (max-width: 991px){
    padding: 0 15px;
  }

  & h2 {
    margin-bottom: 20px;
  }
`

const Icon = styled.div`
  position: absolute;
  left: -1rem;
  top: -2rem;
  z-index: 9;
`

const NoResults = styled.div``

const Filter = ({ className }) => {
  const [state, setState] = useState({
    type: 'buy',
    plaats: [],
    adres: '',
    range: "10",
    startPrice: 0,
    endPrice: 0,
    lat: 0,
    long: 0,
    searching: false
  })

  const handleAddressChange = (e) => {
    const { value } = e.target

    setState({ ...state, adres: value.toUpperCase() })
  }

  const handleStartPriceChange = (e) => {
    setState({ ...state, startPrice: e.target.value })
  }

  const handleEndPriceChange = (e) => {
    setState({ ...state, endPrice: e.target.value })
  }

  useEffect(() => {
    if (state.searching) {
      navigate(
        '/aanbod/woningaanbod',
        {
          state: { search: state }
        }
      )
    }
  })

  const [noResults, setNoResults] = useState(false)

  return (
    <section>
      <div className="container py-5">
        <FilterWrapper className={`color-background-main ${className ? `${className}` : ``}`}>
          <InnerContainer>
            <div className="col-lg-9 position-relative">
              <Icon>
                <svg xmlns="http://www.w3.org/2000/svg" width="237" height="241" viewBox="0 0 237 241" fill="none">
                  <path opacity="0.1" d="M0 241V102.541L117.925 0L194.961 68.9502L175.34 86.2654L117.676 34.6458L95.1016 54.2727L153.248 105.784L133.612 123.099L75.3257 71.4637L50.2327 93.2938L109.514 144.386L89.8467 161.732L30.4257 110.516L26.0103 114.348V215.043H95.7546V171.662L118.189 151.864L121.718 154.905L121.765 156.55V215.043H165.81V151.632L142.178 130.686L161.799 113.371L191.82 139.964V215.043H210.99V118.18L183.86 93.8989L203.48 76.5838L237 106.59V241H0Z" fill="white" />
                </svg>
              </Icon>
              <h2 className="font-weight-l color-text-light font-size-l">Zoek in ons aanbod</h2>
              <RadioButtonContainer className="d-flex justify-content-center justify-content-lg-start">
                <RadioButton selected={state.type === 'buy'} className="cursor-pointer px-4 py-2 mr-3" role="button" tabIndex={0} onKeyDown={() => setState({ ...state, type: 'buy' })} onClick={() => setState({ ...state, type: 'buy' })}>
                  <Paragraph className="color-text-light">Koop</Paragraph>
                </RadioButton>
                <RadioButton selected={state.type === 'rent'} className="cursor-pointer px-4 py-2" role="button" tabIndex={0} onKeyDown={() => setState({ ...state, type: 'rent' })} onClick={() => setState({ ...state, type: 'rent' })}>
                  <Paragraph className="color-text-light">Huur</Paragraph>
                </RadioButton>
              </RadioButtonContainer>
              {noResults && (
                <NoResults className="pt-2 pb-3 d-flex justify-content-center justify-content-lg-start">
                  <Paragraph className="px-3 text-center py-2 color-text-light color-background-contrast font-weight-xl text-uppercase">Zoekopdracht is incorrect, probeer een plaatsnaam toe te voegen</Paragraph>
                </NoResults>
              )}
              <InputContainer className="d-flex flex-wrap justify-content-start">
                <InputWrapper className="d-flex flex-wrap justify-content-lg-start">
                  <RangeContainer className="d-flex py-1 py-lg-0">
                    <Input name="range-filter" value={state.address} onChange={handleAddressChange} className="px-2" theme={{ width: `260px` }} placeholder="Plaats" />
                  </RangeContainer>
                  <PriceContainer className="py-1 py-lg-0 pl-lg-2">
                    <Input type="number" value={state.startPrice === 0 ? '' : state.startPrice} onChange={handleStartPriceChange} className="px-2" theme={{ width: `150px` }} placeholder="Prijs van €" />
                  </PriceContainer>
                  <PriceContainer className="py-1 py-lg-0 pl-lg-2">
                    <Input type="number" value={state.endPrice === 0 ? '' : state.endPrice} onChange={handleEndPriceChange} className="px-2" theme={{ width: `150px` }} placeholder="tot €" />
                  </PriceContainer>
                </InputWrapper>
                <StyledCustomLink className="py-1 py-lg-0 d-flex justify-content-center cursor-pointer ml-3" onClick={() => setState({ ...state, searching: true })} to="/aanbod/woningaanbod" state={{ search: state }}>
                  <Paragraph className="color-background-striking d-flex justify-content-center align-items-center color-text-light">Zoeken</Paragraph>
                </StyledCustomLink>
              </InputContainer>
            </div>
            <div className="col-lg-3 d-none d-lg-block">
              <img src={Groep} width="255" alt="Zoek" />
            </div>
          </InnerContainer>
        </FilterWrapper>
      </div>
    </section>
  )
}

export default Filter