import Plaatjie from '@ubo/plaatjie'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 3rem;

    @media (max-width: 991px){
      font-size: 25px;
    }
`

const ServiceItem = styled(Link)`
  margin-bottom: 20px;

  @media (max-width: 991px){
    padding: 0 5px;
  }

  & .inner {
    border-radius: 20px;
    background: var(--Wit, #FFF);
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
    padding: 25px 35px;

    &:hover {
      background-color: ${(props) => props.theme.color.main};
      color: ${(props) => props.theme.color.text.light};
    }

    @media (max-width: 1299px){
      padding: 25px 15px;
    }
  }

  img {
    margin-bottom: 12px;
    height: 29px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;

    @media (max-width: 991px){
      font-size: 16px;
    }
  }
`

export default function Services({ fields }) {

  return (
    <section className="py-3 py-lg-5">
      <div className="container">
        <Title className="text-center">{fields.title}</Title>
        <div className="d-flex flex-wrap">
          {fields.service.map((item) => (
            <ServiceItem to={item.uri} key={item.databaseId} className="col-12 col-md-4 col-xl-3">
              <div className="inner">
                <Plaatjie image={item.recapService.icon} />
                <h3>{item.title}</h3>
              </div>
            </ServiceItem>
          ))}
        </div>
      </div>
    </section>
  )
}