import React from 'react'
import Plaatjie from '@ubo/plaatjie'
import parse from 'html-react-parser'
import styled from 'styled-components'

// Components
import { Paragraph } from 'components/StyledComponents'

// Third Party
import Content from 'components/Content'
import ButtonAlt from '../Elements/ButtonAlt'


const StyledImg = styled(Plaatjie)`
  & img {
    height: 461px;
    border-radius: 20px;
    object-fit: cover;

    @media (max-width: 991px) {
      height: 270px;
    }

    @media (max-width: 768px) {
      height: 100%;
      width: 100%;
    }
  }
`

const Icon = styled.div`
  right: 0;
  bottom: 0;
`

const Beoordeling = styled.div`
  background-color: ${(props) => props.theme.color.main};
  width: 316px;
  padding: 15px 27px;
  border-radius: 100px;
  bottom: -2.5rem;

  @media (min-width: 992px) {
    right: 2rem;
  }

  & p {
    margin-bottom: 0;
    color: #fff;
  }
`

const Number = styled.p`
  color: #fff;
  font-size: 40px;
  font-weight: bold;
`

const Title = styled.h2`
  font-size: 40px;
    font-weight: bold;
    margin-bottom: 3rem;

    @media (max-width: 991px){
      font-size: 25px;
      margin-bottom: 1.5rem;
    }
`

const OverSnel = ({ data }) => (
  <section>
    <div className="container py-lg-5 position-relative">
      <Icon className="position-absolute">
        <svg xmlns="http://www.w3.org/2000/svg" width="343" height="347" viewBox="0 0 343 347" fill="none">
          <path opacity="0.1" d="M0 347V147.642L170.667 0L282.158 99.2769L253.762 124.208L170.307 49.8842L137.637 78.1437L221.789 152.311L193.371 177.242L109.016 102.896L72.6996 134.328L158.495 207.892L130.031 232.867L44.0338 159.125L37.6436 164.642V309.626H138.582V247.165L171.05 218.659L176.158 223.038L176.225 225.406V309.626H239.969V218.324L205.768 188.166L234.164 163.235L277.613 201.525V309.626H305.356V170.16L266.093 135.199L294.489 110.268L343 153.473V347H0Z" fill="#909090" />
        </svg>
      </Icon>
      <div className="row align-items-center py-5">
        <div className="col-lg-6 mb-4 mb-lg-0">
          <StyledImg loading="lazy" image={data.image} alt="" />
          <Beoordeling className="d-flex align-items-center position-absolute">
            <Content content={data.beoordeling.description} />
            <Number>{parse(data.beoordeling.number)}</Number>
          </Beoordeling>
        </div>
        <div className="col-lg-6 pl-lg-5 mt-5 mt-lg-0">
          <Title>{data.title}</Title>
          <div className="mb-3">
            {parse(data.description)}
          </div>
          <div className="pl-lg-2 mb-5">
            {data.usps.map((usp, index) => (
              <div key={index} className="d-flex align-items-center pl-2 mb-3">
                <img className="pr-3" src={usp.icon.localFile.publicURL} alt="" />
                <Paragraph>{usp.text}</Paragraph>
              </div>
            ))}
          </div>
          <ButtonAlt to={data.link.url}>{data.link.title}</ButtonAlt>
        </div>
      </div>
    </div>
  </section>
)

export default OverSnel