import Plaatjie from '@ubo/plaatjie';
import { Link } from 'gatsby';
import React from 'react'
import styled from 'styled-components';

const Title = styled.h2`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 3rem;

  @media (max-width: 991px){
    font-size: 25px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 2rem;
  }
`

const Image = styled(Plaatjie)`
  height: 272px;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: -1rem;

  @media (max-width: 767px){
    height:  200px;
  }
`

const Item = styled(Link)`

  & .inner {
    height: 105px;
    padding-top: 2.75rem;
    padding-left: 40px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @media (max-width: 991px){
      padding-left: 20px;
    }

    & p {
      color: #fff;

      @media (min-width: 992px){
        font-size: 18px;
      }
    }
  }
`

const Nieuws = ({ fields }) => (
  <section className="py-lg-5  color-background-secondary">
    <div className="container py-5">
      <div className="row">
        <Title className="text-lg-center">{fields.title}</Title>
        <div className="d-flex flex-wrap">
          {fields.items.map((item) => (
            <Item to={`/nieuws${item.uri}`} key={item.databaseId} className="col-12 col-md-6 col-xl-4 mb-4 mb-xl-0">
              <Image image={item.recapPost.image} alt={item.image} />
              <div className="inner color-background-main">
                <p>{item.title}</p>
              </div>
            </Item>
          ))}
        </div>
      </div>
    </div>
  </section>
)

export default Nieuws