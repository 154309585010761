/* eslint-disable arrow-body-style */
import Plaatjie from '@ubo/plaatjie'
import React from 'react'
import styled from 'styled-components'
import ButtonAlt from '../Elements/ButtonAlt'


const StyledSection = styled.section`
  min-height: 300px;

  h4 {
    color: #fff;
    
    @media (min-width: 992px) {
      font-size: 26px;
      font-weight: bold;
      padding-left: 2rem;
      margin-bottom: 0;
    }

    @media (min-width: 1199px) {
      font-size: 40px;
      padding-left: 4rem;
    }

  }
`

const Image = styled(Plaatjie)`
  width: 187px;
  height: 297px;
  position: relative;
  top: 1.5rem;

  @media (min-width: 576px) {
    top: 3.3rem;
  }
`

const CTA = ({ fields }) => {
  return (
    <StyledSection className="color-background-main">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7 d-flex flex-wrap flex-sm-nowrap align-items-center mb-4">
            <Image image={fields.image} className="mb-4 mb-sm-0" />
            <h4>{fields.title}</h4>
          </div>
          <div className="col-md-5 text-sm-center mb-4">
            <ButtonAlt to={fields.link.url}>{fields.link.title}</ButtonAlt>
          </div>
        </div>
      </div>
    </StyledSection>
  )
}

export default CTA