/* eslint-disable arrow-body-style */
import Plaatjie from '@ubo/plaatjie';
import { Link } from 'gatsby';
import React from 'react'
import styled from 'styled-components';

const Locatie = styled.div`
  .inner {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
    padding: 15px;

    img {
      width: 107px;
      height: 107px;
      object-fit: cover;
      border-radius: 12px;
    }

    h3 {
      font-size: 20px;
    }

    a {
      color: ${(props) => props.theme.color.text.contrast};
      font-size: 18px;
      line-height: 27px;
    }
  }
`

const Locaties = ({ fields }) => {

  return (
    <section>
      <div className="container py-lg-4">
        <div className="row py-5 mx-0">
          <h3 className="mb-4 pl-3">{fields.title}</h3>
          <div className="d-flex flex-wrap w-100">
            {fields.locaties.map((item, index) => (
              <Locatie className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0" key={index}>
                <div className="inner d-flex">
                  <Plaatjie image={item.image} />
                  <div className="ml-3 d-flex flex-column justify-content-around">
                    <h3 className="mb-0">{item.locationName}</h3>
                    <p className="mb-0">{item.address}</p>
                    <Link to={item.link.url}>{`${item.link.title} >`}</Link>
                  </div>
                </div>
              </Locatie>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Locaties